<template>
  <b-card-actions
      ref="first-card"
      class="list-card"
      @refresh="refreshStop('first-card')"
  >
    <b-row class="mt-50 mb-5">
      <div class="card-header-row" :style="{backgroundColor: $store.state.colors.headerRowColor}">
        <b-col cols="12" class="d-flex justify-content-between">
          <h4 class="text-white d-inline-block card-title">{{ title }}</h4>
          <div>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="row-btn mr-50"
                :to="`/vardiyaDetayi/${vardiyaID}`"
            >
              <font-awesome-icon icon="database" class="mr-50"/>
              Vardiya Detayı
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                class="row-btn mr-50"
                @click="print()"
            >
              <font-awesome-icon icon="print" class="mr-50"/>
              Yazdır
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="row-btn"
                @click="$router.go(-1)"
            >
              <font-awesome-icon icon="chevron-left" class="mr-50"/>
              Geri Dön
            </b-button>
          </div>
        </b-col>
      </div>
    </b-row>
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        class="mobile-row-btn mr-50"
        :to="`/vardiyaDetayi/${vardiyaID}`"
    >
      <font-awesome-icon icon="database" class="mr-50"/>
      Vardiya Detayı
    </b-button>
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="info"
        class="mobile-row-btn mr-50"
        @click="print()"
    >
      <font-awesome-icon icon="print" class="mr-50"/>
      Yazdır
    </b-button>
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="warning"
        class="mobile-row-btn"
        @click="$router.go(-1)"
    >
      <font-awesome-icon icon="chevron-left" class="mr-50"/>
      Geri Dön
    </b-button>
    <hr>
    <b-table
        ref="ref-adisyon-list"
        striped
        hover
        responsive
        class="position-relative adisyon-list-table mt-1"
        :items="adisyonList"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @row-clicked="goAdisyonDetay"
    >
      <template #cell(userName)="data">
        <font-awesome-icon icon="user"/>
        <p class="font-weight-bolder d-inline-block ml-50 m-0">
          {{ data.item.userName }}
        </p>
      </template>
      <template #cell(toplamTutar)="data">
        <p class="m-0 d-inline-block font-weight-bolder mr-50">{{ data.item.toplamTutar }} TL</p>
      </template>
      <template #cell(adisyonDurum)="data">
        <b-badge :variant="data.item.adisyonDurum === '0'?'light-primary':'light-info'">
          {{ data.item.adisyonDurum === '0' ? 'Kapalı' : 'Açık' }}
        </b-badge>
      </template>
      <template #cell(durum)="data">
        <b-badge :variant="data.item.durum === '0'?'light-warning':'light-success'">
          {{ data.item.durum === '0' ? 'Bekleniyor' : 'Tamamlandı' }}
        </b-badge>
      </template>
      <template #cell(masaAdi)="data">
        <p class="m-0">{{ data.item.masaAdi }}</p>
      </template>
      <template #cell(kayitTarih)="data">
        <p class="m-0">{{ data.item.kayitTarih.slice(0, 16) }}</p>
      </template>
    </b-table>
  </b-card-actions>
</template>

<script>
import {
  BTable,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import axios from 'axios'
import router from '@/router'

export default {
  components: {
    BTable,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BBadge,
    BSpinner,
    ToastificationContent,
    BFormRadio,
    BFormCheckbox,
    BCardText,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      vardiyaID: this.$route.params.id,
      fields: [
        {
          key: 'userName',
          label: 'İşlem Sahibi',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'toplamTutar',
          label: 'Tutar',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'adisyonDurum',
          label: 'Açık / Kapalı',
          thClass: ['text-center'],
          tdClass: ['text-center'],
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'durum',
          label: 'Ödeme Durumu',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'masaAdi',
          label: 'Masa',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '25%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '25%' },

        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {
    title() {
      return 'Adisyon Listesi'
    },
    adisyonList: () => store.getters.GET_ADISYON_LIST,
    vardiya() {
      return {
        vardiyaDetay: store.getters.GET_VARDIYA_DETAY,
      }
    },
  },
  created() {
    this.adisyonListData()
  },
  beforeDestroy() {
    store.commit({
      type: 'SET_ADISYON_LIST',
      action: 0,
    })
  },
  methods: {
    goAdisyonDetay: event => router.push(`/order/${event.masaID}/${event.adisyonID}`),
    print() {
      store.dispatch('tablePrint', this.$refs['ref-adisyon-list'].$el.innerHTML)
    },
    adisyonListData() {
      const fd = this.postSchema()
      fd.set('methodName', 'adisyonListesi')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              store.commit({
                type: 'SET_ADISYON_LIST',
                action: 1,
                list: response.adisyonList,
              })
            }
          })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'vardiya')
      if (this.vardiyaID !== '0' && this.vardiyaID !== 0) {
        fd.append('vardiyaID', this.vardiyaID)
      }
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
}
</script>
<style>

.adisyon-list-table .table td {
  padding: 0.22rem 2rem !important;
}

</style>
